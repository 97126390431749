
a.logo img
  margin-top: -46px
  width: 280px
  @media all and (max-width: 1000px)
    margin-top: -34px
    width: 150px



#welcome
  .container
    margin-top: 200px

.btn-primary
  background-color: #bbdb52
  border-color: #bbdb52
.header-hexagon-bg
  right: 0
.welcome-area
  background-color: #F7FAFD
.hexagon-in2
  max-width: 100%

.paymentmethods

  svg
    display: block
    text-align: center
    margin: 0 auto
  i, span, .fa
    color: white

  i, .fa

    display: block
    font-size: 50px
svg.fa
  display: block
  margin: 0 auto
  @media all and (max-width: 1000px)
    float: none!important
    margin-top: 30px


div.referenceContainer
  clear: both
  overflow: auto
  text-align: center
  div.referenceClub
    display: inline-block
    max-width: 190px
    img
      max-width: 100%
    h1
      display: none
    margin: 30px
